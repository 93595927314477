import {defineStore} from 'pinia';
import {computed, ref} from '#imports';
import {useAuthFetch} from '@/composables/useRequest';
import {GET_WIDGETS, SET_WIDGETS, WIDGETS} from '@/composables/useURL';
import type {IFavoriteTools, IOptions, IPublicTools} from '@/types/pages/cabinet/tools';

export const useToolsStore = defineStore('tools', () => {
  const options = ref<Partial<IOptions>>({});
  const favorite = ref<IFavoriteTools[]>([]);

  const public_ = ref<Partial<IPublicTools>>({});
  const activeTools = computed(() => Object.keys(public_.value));

  const getWidgets = async () => {
    const data = (await useAuthFetch(WIDGETS)) as IOptions;
    options.value = data;
  };

  const getPublicWidgets = async () => {
    const data = (await useAuthFetch(GET_WIDGETS)) as IPublicTools;
    public_.value = data;
  };

  const setWidgets = async () => {
    await useAuthFetch(SET_WIDGETS, {
      method: 'POST',
      body: options.value,
    });
    await getPublicWidgets();
  };

  const getFavorite = async () => {
    const data = (await useAuthFetch('/favorite/lists/')) as IFavoriteTools[];
    favorite.value = data;
  };

  return {
    options,
    favorite,
    activeTools,

    getWidgets,
    getPublicWidgets,
    setWidgets,
    getFavorite,
  };
});
