<template>
  <div v-if="favorites" class="aside-fixed-control__item-body js-init-scroll-bar">
    <div class="aside-fixed-control__item-info">
      Всего {{ favorites.length }} <span class="color-grey">списков</span>
      {{ favoriteSumCount }}
      <span class="color-grey">товаров</span>
    </div>
    <div class="similar-tags">
      <div class="similar-tags__body">
        <div v-for="item in favorites" :key="item.id" class="similar-tags__item">
          <nuxt-link :to="FAVOURITE" class="similar-tags__link" @click="emit('close')">
            {{ item.name }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed} from '#imports';
import {FAVOURITE} from '@/composables/useURL';
import {useToolsStore} from '@/stores/tools';

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const tools = useToolsStore();

await tools.getFavorite();

const favorites = computed(() => tools.favorite);

const favoriteSumCount = computed<number>(() =>
  favorites.value.reduce((prev: number, el) => prev + el.count, 0),
);
</script>
