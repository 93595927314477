import {COMPARE, FAVOURITE, ORDER_HISTORY} from '@/composables/useURL';
import type {IControlTools, ISectionTools} from '@/types/pages/cabinet/tools';
// import compareComp from 'components/cabinet/tools/responsive/_compare.vue';
import favoriteComp from 'components/cabinet/tools/responsive/_favorite.vue';
// import orderComp from 'components/cabinet/tools/responsive/_order.vue';
// import settingComp from 'components/cabinet/tools/responsive/_setting.vue';
// import vendorComp from 'components/cabinet/tools/responsive/_vendor.vue';
import favoriteSvg from 'svg/cabinet/tools/compare.vue';
import compareSvg from 'svg/cabinet/tools/favorite.vue';
import historySvg from 'svg/cabinet/tools/history.vue';
// import settingsSvg from 'svg/cabinet/tools/settings.vue';
// import vendorsSvg from 'svg/cabinet/tools/vendors.vue';

export const sectionList: ISectionTools[] = [
  {
    id: 1,
    value: 'orders_history',
    ico: historySvg,
    name: 'История заказов',
  },
  {
    id: 2,
    value: 'compare_list',
    ico: compareSvg,
    name: 'Списки сравнения',
  },
  {
    id: 3,
    value: 'favorite_list',
    ico: favoriteSvg,
    name: 'Избранные товары',
  },
  // {
  //   id: 4,
  //   value: "configurator",
  //   ico: settingsSvg,
  //   name: "Конфигуратор",
  // },
  // {
  //   id: 5,
  //   value: "vendors",
  //   ico: vendorsSvg,
  //   name: "Вендоры",
  // },
];

export const controlListData: IControlTools[] = [
  {
    id: 1,
    ico: historySvg,
    component: '',
    open: false,
    name: 'История заказов',
    value: 'orders_history',
    url: ORDER_HISTORY,
  },
  // {
  //   id: 2,
  //   ico: settingsSvg,
  //   component: settingComp,
  //   open: false,
  //   name: "Конфигуратор",
  // },
  {
    id: 3,
    ico: favoriteSvg,
    component: favoriteComp,
    open: false,
    name: 'Избранные товары',
    value: 'favorite_list',
    url: FAVOURITE,
  },
  {
    id: 4,
    ico: compareSvg,
    component: '',
    open: false,
    name: 'Списки сравнения',
    value: 'compare_list',
    url: COMPARE,
  },
  // {
  //   id: 5,
  //   ico: vendorsSvg,
  //   component: vendorComp,
  //   open: false,
  //   name: "Вендоры",
  // },
];
